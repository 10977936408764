export default {
    serverProtocol: 'https',
    serverIp: 'back.wikicoin.biz',
    serverPort: '443',
  
    // serverProtocol: 'http',
    // serverIp: "localhost",  
    // serverPort: '18001',

    serverContext: '',
      
    enableForeignExchangeRate: false,
  
    appCode: 'wikicoin'
  };
  